<template>
  <div
    v-loading="$h.isIframeProcessing()"
    :class="{ 'disp-flex-cont': !$route.meta.fixedTopBar }"
  >
    <!-- Header -->
    <div class="container-show-disp" v-loading="$h.isSubmitting() || $h.isFetching()">
      <el-page-header
        v-if="!$route.meta.fixedTopBar"
        title=" "
        :content="$t.translate($route.meta.title)"
        @back="goBack"
      >
      </el-page-header>
      <el-page-header
        v-if="$route.meta.fixedTopBar"
        title=" "
        :content="$t.translate($route.meta.title)"
        @back="goBack"
        class="w-100 bg-white"
        style="position: fixed; z-index: 9"
      >
      </el-page-header>

      <!-- Page content -->
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { router } from "@/utils/router";
import { store } from "@/store";

export default {
  setup() {
    let goBack = () => {
      const userData = store.getters["user/getData"]?.UserData || {};
      if (userData?.UserRole == "CLIENT" && !userData?.IsHaveOrganization) {
        return;
      }
      router.goBack();
    };
    return { goBack };
  },
};
</script>
<style></style>
